import {
  Paper,
  Box,
  Typography,
  useMediaQuery,
  TextField,
  Stack,
  IconButton,
} from "@mui/material";
import StartIcon from "@mui/icons-material/Start";
import { useEffect, useState } from "react";
import Html5QrcodePlugin from "../components/qr-scan";
import { getInfo, scanTicket } from "../api/scan-tool.api";
import { successToast, warningToast, errorToast } from "../components/toast";
import { useParams } from 'react-router-dom';

type ScanModule = {
  id: string;
  name: string;
  limits: number[];
  perDay: boolean;
  createdAt: Date;
  updatedAt: Date;
};

function TicketScan({ token }: { token: string }) {
  const { scanModuleId } = useParams();
  const isWideScreen = useMediaQuery('(min-width: 1000px)');
  const style = {
    marginTop: '3.5em',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 1,
    bgcolor: '#FFFFFF',
    width: isWideScreen ? '40%' : '100%',
  };

  const [delegateNumber, setDelegateNumber] = useState('');
  const [scanResult, setScanResult] = useState('');
  const [eventName, setEventName] = useState('');
  const [scanModule, setScanModule] = useState<ScanModule>();
  const [userName, setUserName] = useState('');

  const getScanToolInfo = async () => {
    const response = await getInfo(token);
    setEventName(response.eventName);
    setScanModule(
      response.scansubmodules.find(
        (scanmodule) => scanmodule.id === scanModuleId
      )
    );
    setUserName(response.userName);
  };

  useEffect(() => {
    getScanToolInfo();
  }, []);
  useEffect(() => {
    setDelegateNumber(scanResult);
  }, [scanResult]);

  const submitDelegateNumber = async () => {
    if (scanModuleId) {
      const result = await scanTicket(scanModuleId, delegateNumber, token);
      if (result && !result.limitReached && !result.nominationInactive) {
        successToast(
          `${result.name} (${
            result.delegateNumber
          }) heute um ${new Date().toLocaleTimeString('de', {
            hour: '2-digit',
            minute: '2-digit',
          })} zum ${result.alreadyScanned}. mal erfolgreich erfasst`
        );
        setDelegateNumber('');
      }
      if (result && result.limitReached) {
        warningToast(
          `${result.name} (${result.delegateNumber}) hat die maximale Anzahl an Scans (${result.scanLimit}) bereits erreicht`
        );
        setDelegateNumber('');
      }
      if (result && result.nominationInactive) {
        errorToast(
          `${result.name} (${result.delegateNumber}) nicht berechtigt`
        );
        setDelegateNumber('');
      }
    }
  };

  const onNewScanResult = (decodedText: string, decodedResult: any) => {
    if (decodedText) {
      setScanResult(decodedText);
    }
  };

  return (
    <Paper sx={style} elevation={5}>
      <Box p={3}>
        <Stack spacing={3}>
          <Typography
            align="left"
            variant="h4"
            sx={{ fontWeight: '600', color: '#00537D' }}
          >
            {scanModule?.name}
          </Typography>
          <Typography
            align="left"
            variant="h5"
            sx={{ fontWeight: '600', color: '#00537D' }}
          >
            {`${eventName} - ${userName}`}
          </Typography>
          <Html5QrcodePlugin
            fps={10}
            qrbox={250}
            disableFlip={false}
            qrCodeSuccessCallback={onNewScanResult}
          />

          <Box display="flex" width="100%" justifyContent="center">
            <TextField
              value={delegateNumber}
              onChange={(e) => setDelegateNumber(e.target.value)}
              label="Delegiertennummer"
            />
            <IconButton
              onClick={submitDelegateNumber}
              disabled={!delegateNumber}
              size="large"
              color="primary"
            >
              <StartIcon />
            </IconButton>
          </Box>
        </Stack>
      </Box>
    </Paper>
  );
}

export default TicketScan;
