import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import toast from "react-hot-toast";
export const errorToast = (message: string) => {
  toast.custom(
    (t) => (
      <Box
        sx={{ backgroundColor: "#FCEDEC", borderRadius: 1, p: 1 }}
        display="flex"
        alignItems="center"
      >
        <ErrorOutlineOutlinedIcon color="error" />
        <Typography color="#602120" pl={1}>
          {message}
        </Typography>
        <IconButton size="small" onClick={() => toast.remove(t.id)}>
          <CloseRoundedIcon fontSize="small" />
        </IconButton>
      </Box>
    ),
    {
      duration: 5000,
    }
  );
};

export const warningToast = (message: string) => {
  toast.custom(
    (t) => (
      <Box
        sx={{ backgroundColor: "#FEF4E5", borderRadius: 1, p: 1 }}
        display="flex"
        alignItems="center"
      >
        <ErrorOutlineOutlinedIcon color="warning" />
        <Typography color="#663D00" pl={1}>
          {message}
        </Typography>
        <IconButton size="small" onClick={() => toast.remove(t.id)}>
          <CloseRoundedIcon fontSize="small" />
        </IconButton>
      </Box>
    ),
    {
      duration: 5000,
    }
  );
};

export const infoToast = (message: string) => {
  toast.custom(
    (t) => (
      <Box
        sx={{ backgroundColor: "#E5F5FD", borderRadius: 1, p: 1 }}
        display="flex"
        alignItems="center"
      >
        <ErrorOutlineOutlinedIcon color="info" />
        <Typography color="#004360" pl={1}>
          {message}
        </Typography>
        <IconButton size="small" onClick={() => toast.remove(t.id)}>
          <CloseRoundedIcon fontSize="small" />
        </IconButton>
      </Box>
    ),
    {
      duration: 5000,
    }
  );
};

export const successToast = (message: string) => {
  toast.custom(
    (t) => (
      <Box
        sx={{ backgroundColor: "#EDF7ED", borderRadius: 1, p: 1 }}
        display="flex"
        alignItems="center"
      >
        <ErrorOutlineOutlinedIcon color="success" />
        <Typography color="#1D4620" pl={1}>
          {message}
        </Typography>
        <IconButton size="small" onClick={() => toast.remove(t.id)}>
          <CloseRoundedIcon fontSize="small" />
        </IconButton>
      </Box>
    ),
    {
      duration: 5000,
    }
  );
};
