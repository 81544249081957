import React from 'react';
import { ThemeProvider } from "@mui/material";
import { Toaster } from "react-hot-toast";
import { createTheme } from "@mui/material/styles";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { useMediaQuery } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00537e",
    },
    secondary: {
      main: "#424242",
    },
    background: {
      default: "#f5f5f5",
    },
    success: {
      main: "#75a017",
    },
    info: {
      main: "#337597",
    },
    warning: {
      main: "#ff9800",
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Toaster
        toastOptions={{
          position: "bottom-center",
          error: {
            duration: 5000,
            style: {
              background: "#FFB7B7",
            },
          },
          custom: {
            duration: 5000,
          },
        }}
      />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

