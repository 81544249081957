import { errorToast } from "../components/toast";

type Info = {
  eventName: string;
  userName: string;
  scansubmodules: [
    {
      id: string;
      name: string;
      limits: number[];
      perDay: boolean;
      createdAt: Date;
      updatedAt: Date;
    }
  ];
};

type Token = {
  token: string;
};

type ScanResult = {
  name: string;
  delegateNumber: string;
  alreadyScanned: number;
  scanLimit: number;
  limitReached: boolean;
  nominationInactive: boolean;
};

export async function getInfo(token: string): Promise<Info> {
  const response = await fetch(`/api/v1/info`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return await response.json();
}

export async function scanToolLogin(pw: string): Promise<Token> {
  const response = await fetch(`/api/v1/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ password: pw }),
  });
  if (response.status >= 500) {
    throw new Error('INTERNAL_SERVER_ERROR');
  }
  if (response.status >= 400) {
    errorToast('Ungültiger Scan-PIN');
    throw new Error('INVALID_PIN');
  }
  return await response.json();
}

export async function scanTicket(
  scanSubModuleId: string,
  dn: string,
  token: string
): Promise<ScanResult> {
  const response = await fetch(`/api/v1/scan`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      scanSubModuleId: scanSubModuleId,
      delegateNumber: dn,
    }),
  });
  if (response.status >= 500) {
    const message = await response.json();
    errorToast(message.textDE);
    throw new Error('INTERNAL_SERVER_ERROR');
  }
  if (response.status === 401) {
    const message = await response.json();
    errorToast(message.textDE);
    throw new Error('INVALID_TOKEN');
  }
  if (response.status === 400) {
    const message = await response.json();
    errorToast(message.textDE);
    throw new Error('UNAUTHORIZED');
  }
  if (response.status === 404) {
    errorToast(`${dn} ist keine gültige Delegiertennummer`);
    throw new Error('INVALID_INPUT');
  }
  if (response.status === 403) {
    console.log(response.status);
    const message = await response.json();
    errorToast(message.textDE);
    throw new Error('SCAN_PERSON_FORBIDDEN');
  }
  return await response.json();
}