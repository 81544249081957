import {
  Alert,
  Paper,
  Box,
  Typography,
  useMediaQuery,
  TextField,
  Stack,
  Button,
  Card,
  CardHeader,
  CardActions,
  CircularProgress,
} from '@mui/material';
import StartIcon from '@mui/icons-material/Start';
import { useEffect, useState } from 'react';
import Html5QrcodePlugin from '../components/qr-scan';
import { getInfo, scanTicket } from '../api/scan-tool.api';
import { successToast, warningToast, errorToast } from '../components/toast';
import StartRoundedIcon from '@mui/icons-material/StartRounded';
import { useNavigate } from 'react-router-dom';

const style = {
  marginTop: '3.5em',
  position: 'absolute',
  top: '5%',
  left: '5%',
  width: '100%',
};

type ScanModules = {
  id: string;
  name: string;
  limits: number[];
  perDay: boolean;
  createdAt: Date;
  updatedAt: Date;
};

function ModuleSelect({ token }: { token: string }) {
  const isWideScreen = useMediaQuery('(min-width: 1000px)');
  const navigate = useNavigate();
  const [scanModules, setScanModules] = useState<ScanModules[]>([]);
  const [eventName, setEventName] = useState('');
  const [userName, setUserName] = useState('');
  const getScanModules = async () => {
    const response = await getInfo(token);
    setScanModules(response.scansubmodules);
    setEventName(response.eventName);
    setUserName(response.userName);
  };

  useEffect(() => {
    getScanModules();
  }, []);
  const navigateToScanModule = (selectedModuleId: string) => {
    navigate(`/${selectedModuleId}`);
  };

  return (
    <Box sx={style}>
      <Typography align="left" variant="h3" sx={{ color: '#00537D' }}>
        Scan-Modul Auswahl {eventName} - {userName}
      </Typography>
      {scanModules.length === 0 ? (
        <Alert severity="info" sx={{ width: '60ch' }}>
          Es wurden noch keine Scan-Module für dieses Event erstellt
        </Alert>
      ) : (
        <Box display="flex" flexDirection="row">
          {scanModules.map((scanModule: any) => (
            <Card elevation={5} sx={{ width: 300, m: 3, p: 1 }}>
              <CardHeader title={scanModule.name} />
              <CardActions>
                <Button
                  variant="outlined"
                  onClick={() => navigateToScanModule(scanModule.id)}
                  endIcon={<StartRoundedIcon />}
                >
                  Zum Scan-Modul
                </Button>
              </CardActions>
            </Card>
          ))}
        </Box>
      )}
    </Box>
  );
}

export default ModuleSelect;
